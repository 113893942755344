import { Container, Box, Heading } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { ContactForm } from "./ContactForm"
import { memo } from "react"

import type { Props } from "@app/pages/contact"
import type { PageProps } from "@root/types/global"

const Contact: React.FC<PageProps<Props>> = ({ page }) => {
  const { getContent } = useContent({ type: page?._type })
  const content = getContent({ content: page?.content })

  return (
    <Box as="section" pt={[16, 20]} pb={[10, 18]}>
      <Container variant="contact">
        <Heading as="h1" size={["3xl", "h2"]} mb={[8, 12]}>
          {page?.title}
        </Heading>
        <Box mb={[10, 12]} lineHeight="24px">
          {content}
        </Box>
        <ContactForm />
      </Container>
    </Box>
  )
}

export default memo(Contact)
