import { useState, useCallback, useMemo, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFunctions } from "@app/hooks/useFunctions"
import { useConfigContext } from "@app/providers/config"

// Usage: this hook retrieves documents in sanity under settings -> forms by handle.
// and handles submission back to Sanity's Form Submission documents
// via the `form-submission` gatsby function
// eg `getForm('contact')` will get the form with the handle `contact`

type Data = {
  [key: string]: string
}

const INITIAL_STATE = {
  name: "",
  email: "",
  type: "",
  message: "",
  dropdownOptions: [],
}

const useForm = (rawInitialState?: Data) => {
  const {
    store: { locationLookupUrl },
    settings: { functions },
  } = useConfigContext()
  const { callFunction } = useFunctions()

  const { forms } = useStaticQuery<GatsbyTypes.StaticFormsQuery>(graphql`
    query StaticForms {
      forms: allSanitySettingForms {
        edges {
          node {
            title
            handle {
              current
            }
            subject
            _id
            recipients
            formFields {
              ... on SanityObjectFormField {
                _key
                _type
                fieldType
                fieldWidth
                placeholder
              }
              ... on SanityObjectFormDropdownOptions {
                _key
                _type
                fieldWidth
                options
                title
              }
            }
          }
        }
      }
    }
  `)

  const initialState = useMemo(() => rawInitialState || INITIAL_STATE, [rawInitialState])

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState(initialState)
  const [success, setSuccess] = useState(false)

  const submitForm = useCallback(
    async (form, data) => {
      setLoading(true)
      setErrors([])

      const id = form?._id
      const type = form?.types?.[data?.type?.toLowerCase()]?.title.toLowerCase()
      const subject = form?.subject || `Form submission from ${form?.title}`
      const response = await fetch(locationLookupUrl)
      const userData = await response.json()

      const { status, body } = await callFunction(functions.formSubmission, {
        id,
        type,
        subject,
        data,
        ipData: userData,
        userAgent: navigator.userAgent || "",
      })

      if (status === "error") setErrors([body])
      if (status === "success") {
        setData(INITIAL_STATE)
        setSuccess(true)
      }

      setLoading(false)
    },
    [setLoading, setErrors, setData, locationLookupUrl, callFunction, functions]
  )

  const getForm = useCallback(
    (handle?: string) =>
      forms?.edges
        ?.filter(({ node }: { node: any }) => node?.handle?.current === handle)
        .map(({ node }: { node: any }) => ({
          ...node,
          handle: node?.handle?.current,
          // types: Object.assign({}, ...node.types.map((type: any) => ({ [type?.title?.toLowerCase()]: { ...type } }))),
        }))[0] || false,
    [forms?.edges]
  )

  const handleChange = useCallback(({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }, [])

  return { getForm, submitForm, data, setData, handleChange, loading, errors, success }
}

const useFocus = () => {
  const ref = useRef(null)
  const setFocus = () => {
    //@ts-ignore next-line
    ref.current && ref.current.focus()
  }

  return [ref, setFocus]
}

export { useForm, useFocus }
