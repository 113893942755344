import { Button, Stack, Box, Grid, GridItem, Text } from "@chakra-ui/react"
import {
  useFormBuilder,
  FIELD_TYPES,
  ControlledInput,
  ControlledTextarea,
  ControlledSelect,
  FunctionErrors,
} from "@app/hooks/useFormBuilder"
import { useForm } from "@app/hooks/useForm"
import { memo } from "react"

import type { Option } from "@components/Select"

type FormProps = {
  buttonText?: string
  formHandle?: string
  nutritionistName?: string
}

const widthMappings = {
  third: [1, 1, 2],
  half: [1, 1, 3],
  full: [1, 1, 6],
}

const FormFieldSerializer = ({ control, setData, register, handleChange, data, fieldErrors, field }: any) => {
  if (field?._type === "objectFormDropdownOptions") {
    const optionsList: Array<Option> = field?.options?.map((option: string) => ({
      label: option,
      value: option,
    }))

    if (!optionsList?.length) return null

    return (
      <ControlledSelect
        key={field?.title}
        control={control}
        name={field?._type}
        options={optionsList}
        placeholder={field?.title}
        setData={setData}
        formTypeTitle={field?.title}
      />
    )
  }

  switch (field?.fieldType) {
    case "message":
      return (
        <ControlledTextarea
          type={FIELD_TYPES.MESSAGE}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          placeholder={field?.placeholder}
        />
      )

    case "name":
      return (
        <ControlledInput
          type={FIELD_TYPES.FULL_NAME}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          placeholder={field?.placeholder}
        />
      )
    case "numberOfBoxes":
      return (
        <ControlledInput
          type={FIELD_TYPES.NUMBER_OF_BOXES}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          placeholder={field?.placeholder}
        />
      )
    case "deliveryDate":
      return (
        <ControlledInput
          type={FIELD_TYPES.DELIVERY_DATE}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          placeholder={field?.placeholder}
        />
      )
    case "company":
      return (
        <ControlledInput
          type={FIELD_TYPES.COMPANY}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          placeholder={field?.placeholder}
        />
      )
    case "addressForDelivery":
      return (
        <ControlledInput
          type={FIELD_TYPES.ADDRESS}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          placeholder={field?.placeholder}
        />
      )
    case "email":
      return (
        <ControlledInput
          type={FIELD_TYPES.EMAIL}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          showAsterisk
          placeholder={field?.placeholder}
        />
      )
    case "phone":
      return (
        <ControlledInput
          type={FIELD_TYPES.PHONE}
          register={register}
          onChange={handleChange}
          fieldErrors={fieldErrors}
          data={data}
          placeholder={field?.placeholder}
        />
      )

    default:
      return null
  }
}

const Form: React.FC<FormProps> = ({ formHandle, buttonText, nutritionistName }) => {
  const { getForm, handleChange, submitForm: handleContactSubmit, data, loading, errors: functionErrors, setData, success } = useForm()

  const form = getForm(formHandle)

  const { fieldErrors, register, handleSubmit, control } = useFormBuilder({
    onSubmit: async (/* formData: any */) => {
      const formData = {
        activeNutritionistProfile: nutritionistName || "",
        ...data,
      }
      return await handleContactSubmit(form, formData)
    },
  })

  if (!formHandle) return null

  return (
    <Box as="form" onSubmit={handleSubmit} mb={8}>
      <Stack spacing={[2, 4]}>
        <Grid
          templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(6, 1fr)"]}
          rowGap={[2, 2, 4]}
          columnGap={[0, 0, 4]}
          alignItems="flex-start"
        >
          {form?.formFields?.map((field: any) => (
            <GridItem key={field?._key} colSpan={widthMappings[field?.fieldWidth as keyof typeof widthMappings]}>
              <FormFieldSerializer
                control={control}
                setData={setData}
                register={register}
                handleChange={handleChange}
                data={data}
                fieldErrors={fieldErrors}
                field={field}
              />
            </GridItem>
          ))}
        </Grid>

        {buttonText && (
          <Button type="submit" isDisabled={loading} isLoading={loading} w={["full", 49]} size="lg">
            {buttonText}
          </Button>
        )}

        <FunctionErrors errors={functionErrors} />
        {success && <Text>Successfully submitted form</Text>}
      </Stack>
    </Box>
  )
}

const MemoForm = memo(Form)
export { MemoForm as Form }
