import { memo } from "react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { Form } from "@components/Form"

const ContactForm: React.FC = () => {
  const locales = useLocale(LOCALE_KEYS.CONTACT)

  return <Form formHandle="contact" buttonText={locales?.contactPageSubmitBtn} />
}

const MemoContactForm = memo(ContactForm)
export { MemoContactForm as ContactForm }
